@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&family=Source+Sans+Pro:wght@300;400;700&display=swap');
@import url("https://use.typekit.net/oji1qep.css");

:root {
  --background: white;
  --text-primary: #000;
  --text-secondary: #fcb017;
  --text-triary: #88d0c6;
  --accent: #87c76326;
}

html {
    font-size: 18px;
    -webkit-transform: translateZ(0);
    -webkit-transform: translate3d(0,0,0);
}

body {
    margin: 0;
    font-family: source-sans-pro, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    line-height:1.4;
    font-weight:400;
}

p{
  font-family: source-sans-pro, sans-serif;
  font-size: clamp(0.888rem, 4vw, 1rem);
  font-weight:400;
  color:#afafaf;
}

p.highlight {
  border-radius: 7px;
  border: 2px solid #789dc1;
  padding: 0.6rem;
  background: #6ea0d369;
  color: #3a6ea3;
}

* {
    font-family: source-sans-pro, Helvetica, Arial, sans-serif;
	  font-weight: 700;
    color: var(--text-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a:hover {
  opacity:0.8;
  transition:0.2s ease-in-out;
  cursor: pointer;
}

span{
  font-family: source-sans-pro, sans-serif;
  font-weight:400;
  font-size: clamp(0.888rem, 4vw, 1rem);
  color:#afafaf;
}

p.desc{
  font-size: clamp(0.7rem, 4vw, 0.72rem);
  line-height: 1.2;
  margin: 0.2rem 0 0rem 0;
  padding-left: 0.2rem;
  color: #dadada;
}

ul, li{
  font-family: source-sans-pro, sans-serif;
  font-weight:400;
  font-size: clamp(0.888rem, 4vw, 1rem);
  color:#afafaf;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: source-sans-pro, sans-serif;
  color: #2177be;
  text-transform: uppercase;
}

h1 {
    font-size: clamp(1.722rem, 6vw, 6.888rem);
    color: var(--text-primary);
    transition: all .25s;
    text-shadow: var(--text-shadow);
}

h4{
  font-size: clamp(0.8rem, 4vw, 1rem);
}


code {
  font-family: source-sans-pro, Helvetica, Arial, sans-serif;
}

hr{
  height: 2px;
  border: 0;
  background: #2177be;
  border-radius: 50px;
}

._3GnVl{
  left:0;
  top:0;
  right:0;
  bottom:0;
  opacity:.0 !important
}

._37_FJ:before{
  background-image:url('https://fob-api.s3.us-east-2.amazonaws.com/clayon-roku-app-icon.png');
  background-position:center center;
  background-size:contain;
  background-repeat:no-repeat;
  height:15rem;
  width:100%;
  margin-bottom:0.7rem;
  content:'';
}


._3GnVl,._37_FJ{
  position:absolute;
}

._37_FJ{
  z-index:1;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  border:0 !important;
  border-radius:5px;
  width: 50% !important;
  max-width:100% !important;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:stretch;
  align-content:center;
  background-color:#fff;
  color:#2175c9 !important;
}
@media(max-width:892px){
  ._37_FJ{
    width: 80% !important;
  }
}

._37_FJ input {
  height: 3rem;
  width: 97% !important;
  border-radius: 7px;
  border: 0;
  background: #f6f6f6;
  padding-left:1rem;
  font-family: source-sans-pro, Helvetica, Arial, sans-serif !important;
}

@media(max-width:892px){
  ._37_FJ input {
    width: 93% !important;
  }
}

._37_FJ input::placeholder {
}

  
  ._37_FJ>div{
    padding:0 !important;
    margin:0.5rem 0;
  }
  
  ._ovIFV{
    display:none !important;
  }
  
  
  ._1YwH3{
    align-self:flex-end;
    width: 100%;
  
  }
  ._1YwH3 button {
    background: #2175c9 ;
    width: 100% !important;
    border: 0 !important;
    padding: 1rem 2rem;
    border-radius: 7px;
    color: #Fff;
    font-weight:600;
    text-transform:uppercase;
  }

  ._1YwH3 button:hover {
    cursor:pointer;
    opacity:0.7;
    transition:0.2s ease-in-out;
  }

.pulsate {
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite; 
}

@-webkit-keyframes pulsate {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
}
