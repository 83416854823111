
#root {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  display: -webkit-box; 
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex; 
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-line;
  vertical-align: bottom;
}